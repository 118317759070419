
          @import "@/assets/scss/Style.scss";
         
        

.multiselect,
.multiselect>* {
  width: 120px;
}

.multiselect__option--highlight {
  background: $primary !important;
}

#footer {
  padding: 30px 0;
  background: $lighter;

  //removed cause my campaign page
  //margin-top: 120px;
  &.dashboard {
    background: $lighter;

    .footer-wrapper {

      .footer-header .contact-number,
      .footer-body .footer-menus {
        display: none;
      }
    }
  }

  .footer-wrapper {
    display: flex;
    flex-direction: column;
    gap: 61px;

    .footer-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 20px 0;
      @include dashed-border(-30px);

      .contact-number {
        display: flex;
        gap: 15px;

        div {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          span {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.03em;
            color: #384456;
          }

          h3 {
            transition: $trns;

            a {
              font-family: $font-base-family;
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 34px;
              letter-spacing: -0.03em;
              color: #000000;
              transition: $trns;

              &:hover {
                transition: $trns;
                color: #128C7E;
              }
            }
          }
        }
      }

      @media screen and (max-width: 576px) {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;

        .contact-number {
          align-items: center;

          div {
            align-items: flex-start;
          }

          img {
            order: -1;
          }
        }
      }
    }

    .footer-body {
      display: flex;
      flex-direction: column;
      gap: 30px;
      @include dashed-border(-30px);

      p {}
    }
  }
}
