
          @import "@/assets/scss/Style.scss";
         
        

.error-list {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .error-item {
    color: #FF6161;
    padding: 10px 20px;
    list-style: decimal;

  }
}
