
          @import "@/assets/scss/Style.scss";
         
        

html {
  &.oly-sticky-active {
    .breadcrumb {
      top: 50px;
      background: $white;
    }
  }
}
.breadcrumb {
  display: flex;
  align-items: center;
  position: fixed;
  top: 80px;
  transition: $trns;
  background: $white;
  width: 100%;
  margin-bottom: 0 !important;
  border-bottom: 1px solid $light;
  box-shadow: 0 25px 40px -15px #eff0f2;
  z-index: 1000;
  * {
    //border-bottom: 0 !important;
  }
  ul {
    display: flex;
    gap: 14px;
    margin-bottom: 0;
    border-bottom: 0;
    padding-left: 0;
    padding: 15px 0 !important;
    align-content: center;
    width: fit-content;
    li {
      height: max-content;
      &:not(:last-child) {
        position: relative;
        top: -1px;
        &::before {
          content: "/";
          position: absolute;
          top: 56%;
          right: -9px;
          color: $gray;
          font-size: 12px;
          line-height: 16px;
          transform: translateY(-50%);
        }
      }
      a {
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        &:hover {
          color: $gray;
        }
      }
    }
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .home-link {
      display: flex;
      align-items: center;
      gap: 12px;
      background-color: $primary;
      padding: 12px 18px;
      height: 100%;
      transition: all 0.3s ease;
      p {
        font-size: 14px;
        font-weight: 600;
        color: $light;
        margin: 0;
      }
      &:hover {
        background-color: $secondary;
      }
      &:hover svg .colorful-path {
        transition: all 0.3s ease;
        fill: $secondary;
      }
    }
  }
}
