
          @import "@/assets/scss/Style.scss";
         
        

ul {
  display: flex;
  gap: 21px;
  text-transform: capitalize;
  li a {
    cursor: pointer;
    color: $gray;
    &:not(:last-child) {
      position: relative;
      &::before {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -10px;
        border: 1px dashed rgba($gray, 0.2);
      }
    }
  }
}
