
          @import "@/assets/scss/Style.scss";
         
        

#footer .footer-wrapper {
  .footer-copy {
    img {
      width: 100px;
    }

    .col-12 {
      @media screen and (max-width: 576px) {
        flex-direction: column;
        gap: 16px;
      }
    }

    .row {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .copy {
      font-size: 16px;
    }

    p {
      line-height: 16px;
      margin-bottom: 0;
      color: #384456;

      &:last-child {
        opacity: 0.5;
      }
    }
  }
}