
          @import "@/assets/scss/Style.scss";
         
        

.modal {
  .code-inputs .inputs input {
    @media screen and (max-width: 600px) {
      width: 40px;
      height: 40px;
    }
  }
}

.inputs {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  margin-top: 30px;
  justify-content: space-evenly;
  @media screen and (max-width: 774px) {
    gap: 5px;
  }

  input {
    background: $lighter;
    border: 1px solid $light;
    width: 56px;
    height: 58px;
    text-align: center;

    &:focus,
    &.valid {
      outline: none;
      border-color: $primary;
    }

    &.bg-white {
      background-color: white;
    }
  }

  button {
    &.valid {
      background-color: white;
    }
  }
}
