
          @import "@/assets/scss/Style.scss";
         
        

@import "~@vueform/multiselect/themes/default.css";
#multiselect {
  .multiselect-single-label,
  .multiselect-placeholder,
  .multiselect-search input,
  .multiselect-options .multiselect-option span {
    font-size: 16px;
    line-height: 34px;
  }
  .multiselect-input {
    height: 100%;
    background: #fbfbfb;
    border: 1px solid #EFF0F2;
    backdrop-filter: blur(6px);
  }
  .multiselect-clear {
    background: #fbfbfb;
  }
  .multiselect-options {
    width: 100%;
    overflow: unset;
    &.is-pointed {
      background-color: red !important;
    }
  }
}
