
          @import "@/assets/scss/Style.scss";
         
        

.layout-outer-wrapper {
  min-height: calc(100vh - 495px);
  background: $lighter;
  padding: 50px 0;
}
.off-menu-open {
  overflow: hidden;
  .breadcrumb,
  .layout-outer-wrapper,
  #header {
    overflow: hidden;
    animation: open 0.4s ease-in-out forwards;
  }
  .outer-nav-menu {
    z-index: 1000;
    transform: translateZ(0);
    button {
      box-shadow: 0px 8px 40px -12px rgba(243, 110, 42, 0.3);
    }
  }
}
.off-menu-close {
  .breadcrumb,
  .layout-outer-wrapper,
  #header {
    animation: close 0.4s ease-in-out forwards;
  }
}
.root-wrapper {
  backface-visibility: hidden;
}
@keyframes open {
  0% {
    transform: translateZ(0);
  }
  70% {
    transform: translate3d(240px, 0, 0);
  }
  100% {
    transform: translate3d(240px, 0, 0);
  }
}
@keyframes close {
  0% {
    transform: translate3d(240px, 0, 0);
  }
  100% {
    transform: translateZ(0);
  }
}
