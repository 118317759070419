
          @import "@/assets/scss/Style.scss";
         
        

.info-header {
  background: $warning;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 8px;
  p {
    color: #000;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.36px;
  }
  svg {
    position: absolute;
    right: 10px;
    z-index: 1000;
    cursor: pointer;
  }
}
