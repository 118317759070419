
          @import "@/assets/scss/Style.scss";
         
        

html {
  #header {
    .outer-wrapper {
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      padding: 0;
      border: 0;

      .header-row {
        &:first-child button {
          padding: 0 34px 0 15px;
        }

        &:last-child {
          padding: 0 13px;
          border: 1px solid rgba($white, 0.2);
          border-top: 0;
          border-bottom: 0;
        }

        .logo {
          color: white;
          letter-spacing: -0.06em;
          font-size: 24px;
          line-height: 24px;
          transition: .3s ease all;
          @media screen and (max-width: 554px) {
            font-size: 18px;
          }
        }
      }

      @media screen and (min-width: 992px) {
        .left-nav {
          display: none;
        }
      }
    }

    img {
      &:first-child {
        opacity: 1;
        display: block;
      }

      &:last-child {
        opacity: 0;
        display: none;
      }
    }

    &.white {
      .outer-wrapper {
        .header-row:last-child {
          border-color: $light;
        }
      }

      img {
        &:first-child {
          opacity: 0;
          display: none;
        }

        &:last-child {
          opacity: 1 !important;
          display: block !important;
        }
      }
    }
  }

  &.oly-sticky-active {
    #header {
      .outer-wrapper {
        .header-row:last-child {
          border-color: $light;
        }

        .header-logo {
          margin: 0;
        }

      }

      img {
        &:first-child {
          opacity: 0;
          display: none;
        }

        &:last-child {
          opacity: 1 !important;
          display: block !important;
        }
      }
    }
  }
}
