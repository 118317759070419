
          @import "@/assets/scss/Style.scss";
         
        

.social-wrapper {
  display: flex;
  gap: 10px;

  .social-list-icon {
    width: 50px;
    height: 50px;
    border: 1px solid $light;
    background: #fbfbfb;
    transition: $trns;

    &.small {
      width: 35px;
      height: 35px;
    }

    &.light {
      background: $light;
      font-size: 12px;
    }

    &:hover {
      background: $secondary;
      border-color: transparent;

      svg {
        color: $white;
        transition: $trns;
      }
    }

    svg {
      color: $gray;
      transition: $trns;
    }
  }
}
