
          @import "@/assets/scss/Style.scss";
         
        

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 30px;
  @include dashed-border();

  .price-area {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    gap: 5px;

    .item {
      border: 1px solid #eff0f2;
      backdrop-filter: blur(6px);
      background: #fbfbfb;
      width: calc(100% / 2 - 2.5px);
      height: 58px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px;
      cursor: pointer;
      transition: $trns;
      font-size: 16px;
      line-height: 34px;
      font-weight: 400;
      color: rgba($gray, .75);

      &:hover,
      &.selected {
        transition: $trns;
        background: $primary;
        color: $white;
      }

      &.disabled {
        background: rgba(108, 117, 125, 0.05) !important;
      }
    }
  }

  .input-wrapper input {
    font-size: 16px;
    line-height: 34px;
  }
}
