
          @import "@/assets/scss/Style.scss";
         
        

#footer .footer-wrapper .footer-body {
  .list-menu {
    p {
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      text-transform: capitalize;
    }
    ul {
      padding-left: 0;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      li {
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        a {
          text-decoration: none;
          transition: $trns;
          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}
